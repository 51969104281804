/*eslint no-unused-vars: ["warn", { "varsIgnorePattern": "^_" }]*/
import remark from 'remark';
import remarkHtml from 'remark-html';

import siteInfo from './config';

export const newsEntries = {};

for (let language of siteInfo.languages) {
    newsEntries[language] = require(`../../content/${language}/news.yml`);
}

const markdownProcessor = remark().use(remarkHtml);

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

const dateFormatter = {
    ja: (y, m, d) => `${y}年${m}月${d}日`,
    en: (y, m, d) => `${monthNames[m - 1]} ${d}, ${y}`,
};

export function processNewsEntry(lang, {date, headline}) {
    const match = date.match(/^([0-9]{4})-0*([0-9]{1,2})-0*([0-9]{1,2})$/);
    if (!match) {
        throw new Error(`Invalid date format: ${date}`);
    }
    const [_, year, month, day] = match;
    return {
        date: dateFormatter[lang](year, month, day),
        headline: {
            __html: markdownProcessor.processSync(headline).toString(),
        },
    };
}